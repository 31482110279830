import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';

const IndexPage = () => {
    let content = [];
    const data = useStaticQuery(
        graphql`
			query {
                allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
                    totalCount
                    edges {
                        node {
                            id
                            frontmatter {
                                title
                                date(formatString: "DD MMMM, YYYY")
                            }
                            fields {
                                slug
                            }
                            excerpt
                        }
                    }
                }
            }
		`
    )
    return (
        <Layout>
            <div className="column">
                {data.allMarkdownRemark.edges.map(({ node }) => (
                    content.push([node.id, node.frontmatter.title, node.frontmatter.date, node.fields.slug, node.excerpt])
                ))}
                {<div className="columns is-vcentered" key={content[0][0]}>
                    <div className="column">
                        <figure class="image is-2by1">
                            <img src="https://bulma.io/images/placeholders/600x480.png" alt="" />
                        </figure>
                    </div>
                    <div className="column">

                        <Link to={content[0][3]}>
                            <h1 className="title is-1">{content[0][1]}</h1>
                        </Link>
                        <span className="is-italic">{content[0][2]}</span>
                        <p className="is-size-4">{content[0][4]} <Link to={content[0][3]}> <br /> Lire la suite</Link></p>
                    </div>
                </div>}

            </div>
        </Layout>
    )
};

export default IndexPage;